import AppLayout from '../components/AppLayout'
import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../contexts/AppContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FiArrowLeft } from "react-icons/fi";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import {TbExternalLink} from "react-icons/tb"

export default function Order() {
    const { orderId } = useParams();

    const { getorder, isLoading } = useContext(AppContext);
    const [order, setOrder] = useState('');
    const [product, setProduct] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
      fetchOrder();
    }, []);
    
    const fetchOrder = async () => {
        const response = await getorder({ orderid: orderId });
        setOrder((response.order));
        setProduct((response.product));
    }

  return (
    <AppLayout>
    <main>
      <div class="mb-5 flex justify-start">
          <Link className='text-white flex items-center space-x-2 font-medium' onClick={() => navigate(-1)}><FiArrowLeft className='text-white' size={16}/> <span>Back</span></Link>
      </div>
      {
      isLoading === false ?
      <>
      {
      order !== "" ? 
      <>
        <div className='lg:flex items-center justify-between bg-black rounded-md p-1 md:p-3 lg:p-5'>
        <table class="w-full text-sm text-left text-white">
            <tbody>
                <tr class="border border-neutral-900">
                    <th scope="row" class="px-3 py-3 lg:px-6 lg:py-4 font-medium border-b border-neutral-950 bg-neutral-900">
                        Product Name:
                    </th>
                    <td class="px-3 py-3 lg:px-6 lg:py-4 flex items-center">
                        {product.name} <Link className='text-blue-500 ml-2' to={`/product/${product.productid}`}><TbExternalLink size={18}/></Link>
                    </td>
                </tr>
                <tr class="border border-neutral-900">
                    <th scope="row" class="px-3 py-3 lg:px-6 lg:py-4 font-medium border-b border-neutral-950 bg-neutral-900">
                        Product Short Description:
                    </th>
                    <td class="px-3 py-3 lg:px-6 lg:py-4">
                        {product.shortdescription}
                    </td>
                </tr>
                <tr class="border border-neutral-900">
                    <th scope="row" class="px-3 py-3 lg:px-6 lg:py-4 font-medium border-b border-neutral-950 bg-neutral-900">
                        Product Price:
                    </th>
                    <td class="px-3 py-3 lg:px-6 lg:py-4">
                        ${product.price}
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
        <div className='mt-16'>
            <div className='flex flex-row justify-center lg:w-1/2 md:w-2/2 space-x-3 mx-auto text-center'>
                {((order.hi5).split("")).map((element, index) => (
                    <div className='bg-white rounded-md w-1/6 text-3xl font-bold p-3 py-3 lg:py-5 text-neutral-800'>{element}</div>
                ))} 
            </div>
            <div className='lg:w-1/2 md:w-2/2 mx-auto font-medium text-neutral-500 mt-2'>Hi-5 code
            </div>
            <div className='mt-16 text-center text-neutral-300'>When your service is complete, share this code with your service provider. This transfers your payment to them.<br/>After you transfer the payment you will not be able to request an instant refund.</div>
        </div>
      </>
      :
      <></>
      }
      </>
      : 
      <div className='flex justify-center w-full py-12'><Spinner color='#CCCCCC' size={24}/></div>
      }
    </main>
    </AppLayout>
  )
}

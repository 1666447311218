import AppLayout from '../components/AppLayout'
import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { RxCross2 } from 'react-icons/rx';
import { AppContext } from '../contexts/AppContext';
import { Link } from 'react-router-dom';
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";


export default function Dashboard() {

    const [search, setSearch] = useState('');
    const [error, setError] = useState('');
    const [products, setProducts] = useState([]);
    const [todelete, setTodelete] = useState('');
    const [modalopen, setModalopen] = useState(false);
    const userToken = JSON.parse(localStorage.getItem('userToken'));
    const { isLoading, getmylistings, deletelisting, getproducts, searchproducts, searchloading } = useContext(AppContext);
    const handleSearch = async (e) => {
      const searchTerm = e.target.value;
      setSearch(searchTerm);
      const response = await searchproducts(searchTerm);
      // Update the state with the received products
      setProducts(response);
    };
    
    const deleteListing = async () => {
        const response = await deletelisting({ productid: todelete });
        if(response === 'success'){
          setModalopen(false);
          setTodelete('');

          const response = await getmylistings();
          setProducts(response);
        }
    }

    const preparedelete = (prod) => {
      setModalopen(true);
      setTodelete(prod);
    };

    const unpreparedelete = () => {
      setModalopen(false);
      setTodelete('');
    };

    useEffect(() => {
      const fetchMyListings = async () => {
          if(userToken.type === 'Buyer'){
          const response = await getproducts();
          // Update the state with the received products
          setProducts(response);
          }
          else if(userToken.type === 'Seller'){
            const response = await getmylistings();
            // Update the state with the received products
            setProducts(response);
          }
      }

      // Call the function and update the state when the component mounts
      fetchMyListings();
    }, []);

  return (
    <AppLayout>
      { userToken.type === 'Buyer' ? 
      <main>
        <div className='mb-3'>
          <h3 className='text-neutral-500 text-lg font-normal'>Hello, {userToken.name}</h3>
          <h3 className='text-white text-3xl font-semibold'>Home</h3>
        </div>
        <div class="items-center justify-between w-full md:flex flex-1 md:order-1 mb-10" id="navbar-cta">
            <div class="relative w-full">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                {searchloading === true ? <Spinner className='w-5 h-5 text-neutral-300'/> : <svg class="w-5 h-5 text-neutral-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>}
                <span class="sr-only">Search icon</span>
            </div>
            <input type="text" id="search-navbar" class="block w-full p-3 pl-10 text-sm" placeholder="Search product title" value={search} onChange={handleSearch}/>
            </div>
        </div>
        {
        isLoading === false ? 
          <>
          { 
          products.length > 0 ? <>
          <div class="grid lg:grid lg:grid-cols-3 grid-cols-1 gap-4 gap-y-4">
          {
            products.map((product, index) => {
              return(
                <div key={index} className='flex-1'>
                <Link to={`/product/${product.productid}`}>
                <div className=" bg-neutral-900 rounded-lg shadow h-full flex flex-col hover:bg-neutral-950 border border-neutral-800">
                    <img class="rounded-t-lg object-cover w-full h-96" src={`data:image/png;base64,${product.image0}`} alt={product.name} />
                    <div class="p-5 flex flex-1 flex-col justify-between">
                        <div>
                        <h5 class="mb-2 text-2xl font-semibold text-white">{product.name}</h5>
                        <p class="mb-2 text-sm text-neutral-500 font-light">{product.shortdescription}</p>
                        </div>
                        <div class="flex items-center justify-between mt-3">
                            <span class="text-2xl font-semibold text-white">${product.price}</span>
                            <div className='flex flex-row items-center space-x-3'>
                            <img className='h-8 w-8 rounded-full' src={`data:image/png;base64,${product.sellerimage}`}/>
                            <div className='text-neutral-600 text-sm font-medium'><span className='tracking-wide'>@{product.sellername}</span></div>
                          </div>
                        </div>
                    </div>
                </div>
                </Link>
                </div>
              )
            }) 
          }
          </div>
          </>
          : <div className='text-white text-center text-2xl font-medium tracking-wide'>No Products found</div>
          }
          </>
        : 
        <div className='flex justify-center text-center w-full py-12'><Spinner color='#CCCCCC' size={24}/></div>
        }
      </main>
      : 
      <main>
        <div className='flex justify-between items-center mb-10'>
          <div>
            <h3 className='text-neutral-500 text-lg font-normal'>Hello, {userToken.name}</h3>
            <h3 className='text-white text-3xl font-semibold'>My store</h3>
          </div>
          <div>
            <Link to="/add-listing" className='btn btn-main !px-14 !rounded-lg !font-semibold'>Add product</Link>
          </div>
        </div>
        {
        isLoading === false ?
        <>
        
        <Transition.Root show={modalopen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={unpreparedelete}>
            <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            >
            <div className="fixed inset-0 hidden bg-black bg-opacity-75 transition-opacity md:block" />
            </Transition.Child>

            <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                enterTo="opacity-100 translate-y-0 md:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 md:scale-100"
                leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                >
                <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-lg md:px-4 lg:max-w-lg">
                    <div className="relative w-full items-center overflow-hidden bg-neutral-900 text-white shadow-2xl p-8">
                    <div className='w-full'>
                      <h5 className='text-xl font-medium mb-2'>Are you sure you want to delete this item?</h5>
                      <h5 className='text-md text-neutral-400 font-light'>This action cannot be undone</h5>
                    <button
                        type="button"
                        className="absolute top-3 right-3 text-gray-400 hover:text-gray-500"
                        onClick={() => unpreparedelete()}>
                        <span className="sr-only">Close</span>
                        <RxCross2 className="h-5 w-5" aria-hidden="true" />
                    </button>
                    </div>
                    <div className='px-3 pt-7 mb-3 flex justify-end space-x-2'>
                      <button disabled={isLoading} className='btn-main-inverse'
                        onClick={() => unpreparedelete()}>Do not delete</button>
                      <button disabled={isLoading} className='btn-main !px-12'
                        onClick={() => deleteListing()}>{isLoading === false ? 'Delete' : <Spinner animating={isLoading}/>}</button>
                    </div>
                    </div>
                </Dialog.Panel>
                </Transition.Child>
            </div>
            </div>
        </Dialog>
        </Transition.Root>
        <div class="grid lg:grid lg:grid-cols-3 grid-cols-1 gap-4 gap-y-4">
        { 
          products.length > 0 ?
          products.map((product, index) => {
            return(
              <div className='flex-1 relative'>
                <div className='absolute z-10 flex flex-row space-x-3 top-3 left-3'>
                  <Link className='bg-blue-500 hover:bg-blue-700 text-sm p-1.5 px-3 rounded-lg' to={`/add-listing/${product.productid}`}>Edit</Link>
                  <button type='button' className='bg-red-500 hover:bg-red-700 text-sm p-1.5 px-3 rounded-lg' onClick={() => preparedelete(product.productid)}>Delete</button>
                </div>
                <Link to={`/product/${product.productid}`}>
                <div className=" bg-neutral-900 rounded-lg shadow h-full flex flex-col hover:bg-neutral-950 border border-neutral-800">
                    <img class="rounded-t-lg object-cover w-full h-96" src={`data:image/png;base64,${product.image0}`} alt={product.name} />
                    <div class="p-5 flex flex-1 flex-col justify-between">
                        <div>
                        <h5 class="mb-2 text-2xl font-semibold text-white">{product.name}</h5>
                        <p class="mb-2 text-sm text-neutral-500 font-light">{product.shortdescription}</p>
                        </div>
                        <div class="flex items-center justify-between mt-3">
                            <span class="text-2xl font-semibold text-white">${product.price}</span>
                        </div>
                    </div>
                </div>
                </Link>
              </div>
            )
          }) 
          : <div className='text-white text-center text-2xl font-medium tracking-wide'>No Products found</div>
          }
        </div>
        </>
        : 
        <div className='flex justify-center w-full py-12'><Spinner color='#CCCCCC' size={24}/></div>
        }
      </main>
      }
    </AppLayout>
  )
}

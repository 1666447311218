import '../styles/auth.css'
import AuthLayout from '../components/AuthLayout'
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import { AiOutlineUser, AiOutlineMail } from "react-icons/ai"
import { MdOutlinePassword } from "react-icons/md"

export default function SignUp() {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [type, setType] = useState('');
    const [isAccepted, setIsAccepted] = useState(false);
    const [error, setError] = useState('');

    const { signup, isLoading, registered } = useContext(AuthContext);

    const handleSubmit = async (e) => {
      e.preventDefault();
      if(isLoading === false){
      if (!type) {
        setError('Please select an account type')
        return;
      }
      else if (!isAccepted) {
        setError('Please accept the terms of service');
        return;
      }
      else if (password.length < 6) {
        setError('Password must have at least 6 characters');
        return;
      }
      try {
        if(email !== "" && name !== "" && password !== ""){
        setError('')
        const response = await signup(name, email, password, type);
        }
        else{
            setError('All fields are required')
            return;
        }
      } catch (error) {
            setError('Signup failed: '+ error)
      }
    }  
    };

  return (
    <AuthLayout>
    <main>
        <section className='container px-3 2xl:px-10 xl:px-10 lg:px-10 md:px-7 mx-auto pt-0 2xl:pt-10 xl:pt-10 lg:pt-10 md:pt-5'>
            <div className='flex flex-col lg:flex-row space-y-5 lg:space-y-0 justify-between items-center'>
                <div>
                    <div className='flex'><Link to="/"><img src='/logo.png' className='w-10 h-10'/></Link></div>
                    <div className='text-white text-xl font-light mt-4 w-full lg:w-6/12 leading-relaxed tracking-wide'><b className='font-semibold'>JUST SELL</b> Helps you sell and purchase to people all around the globe.</div>
                </div>
                <form onSubmit={handleSubmit} className='authform w-full max-w-lg p-12 mb-20'>
                {registered === true ? 
                <>
                    <h3 className='text-white text-2xl font-semibold text-center mb-4'>Registered Successfully</h3>
                    <div className='text-neutral-400 text-center'>You have successfully registered! Please check your email for a verification link. Click on the link to verify your account.</div>
                    <div class="btn-main w-full uppercase text-center mt-10">Thank You</div>
                </>
                : 
                <>
                    <div className='flex flex-row text-white text-lg font-semibold text-center border-2 border-main'>
                        <div className='w-1/2 bg-main p-3'>Sign Up</div>
                        <Link to={'/login'} className='w-1/2 p-3 border-main transition-colors hover:bg-black'>Log In</Link>
                    </div>
                    <div class="radios pt-5 mb-6">
                        <div class="radio">
                            <input type="radio" id="Seller" value="Seller" name="type" checked={type === 'Seller'} onChange={(e) => setType(e.target.value)}/>
                            <label class="radiolabel" for="Seller">
                                <div class="checker"></div>
                                I'm a seller
                            </label>
                        </div>
                        <div class="radio">
                            <input type="radio" id="Buyer" value="Buyer" name="type" checked={type === 'Buyer'} onChange={(e) => setType(e.target.value)}/>
                            <label class="radiolabel" for="Buyer">
                                <div class="checker"></div>
                                I'm a buyer
                            </label>
                        </div>
                    </div>

                    <div className='fields space-y-7 mb-4'>
                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                                <AiOutlineUser color='#777'/>
                            </div>
                            <input type="name" className='!pl-10' id="name" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} required/>
                        </div>

                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                                <AiOutlineMail color='#777'/>
                            </div>
                            <input type="email" className='!pl-10' id="email" placeholder="E-Mail" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                        </div>

                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                                <MdOutlinePassword color='#777'/>
                            </div>
                            <input type="password" className='!pl-10' id="password" placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} required/>
                        </div>
                    </div>
                    {error !== "" ? <div className='text-center text-red-500 font-semibold text-md mb-3'>{error}</div> : <div className='py-5'></div>}
                    <div class="flex items-start mb-5">
                        <div class="flex items-center h-5">
                        <input id="remember" type="checkbox" value="" checked={isAccepted} onChange={(e) => setIsAccepted(!isAccepted)} class="w-4 h-4 border border-gray-300 rounded bg-transparent focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800" required/>
                        </div>
                        <label for="remember" class="ml-2 pt-0.5 text-xs text-neutral-400 font-light"> I agree to the Terms of Service and Privacy Policy</label>
                    </div>
                    <button type="submit" class="btn-main w-full uppercase" disabled={isLoading}>{isLoading === false ? 'Create an account' : <Bounce animating={isLoading}/>}</button>
                </>
                }
                </form>
            </div>
        </section>
    </main>
    </AuthLayout>
  )
}

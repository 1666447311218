import '../styles/auth.css'
import AuthLayout from '../components/AuthLayout'
import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Link, useParams } from 'react-router-dom';
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { FiArrowRight } from "react-icons/fi";

export default function Verify() {

    const { token } = useParams();
    const [resp, setResp] = useState('');
    const { verify, isLoading } = useContext(AuthContext);
    
    const verifyToken = async () => {
        const response = await verify(token);
        setResp((response !== undefined ? response : ''))
    }

    useEffect(() => {
        verifyToken()
    }, []);

  return (
    <AuthLayout>
    <main>
        <section className='max-w-lg mx-auto pt-10'>
            <div className='authform p-10 lg:px-16 mb-20'>
                <h3 className='text-white text-2xl font-semibold text-center mb-4'>Account Verification</h3>
                {
                    isLoading === true ? 
                    <>
                        <div className='w-full flex flex-col items-center justify-center my-10 space-y-5'>
                            <Spinner size={26}/>
                            <h5 className='text-md text-neutral-300'>Verifying... Please wait..</h5>
                        </div>
                    </>
                    :
                    <>
                    {
                        resp !== '' ? <>{resp === 'success' ? <><div className='text-neutral-400 text-center'>We are delighted to inform you that your account has been successfully verified. Congratulations!</div>
                        <Link to={'/login'}><div class="btn-main w-full uppercase text-center mt-10 !flex justify-center items-center">Thank You, Login <FiArrowRight className='ml-1'/></div></Link></> : <><div className='text-red-400 text-center text-lg my-16 py-1'>{resp}</div></>}</> : <div className='text-red-400 text-center text-lg my-16 py-1'></div>
                    }
                        
                    </>
                }
            </div>
        </section>
    </main>
    </AuthLayout>
  )
}

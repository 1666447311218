import React from 'react';
import AppHeader from './AppHeader';
import Footer from './Footer';

const AuthLayout = ({ children, nopadding }) => {
  const userToken = JSON.parse(localStorage.getItem('userToken'));
  return (
        <>
        <AppHeader />
        <div class={`${nopadding ? 'p-0' : 'p-6 pt-0 md:p-7 lg:p-12'} sm:ml-64 flex flex-col min-h-screen app-box relative`}>
        <div className='absolute top-5 left-0'>{userToken.type === 'Seller' ? <img src='/images/seller.png' className='w-24 h-24'/> : <img src='/images/seller.png' className='w-24 h-24'/>}</div>
        {children}
        </div>
        </>
  );
};

export default AuthLayout;
import '../styles/footer.css'
import { Link } from 'react-router-dom'

export default function AuthFooter() {
  return (
    <footer className='!bg-transparent'>
        <div class="mx-auto w-full container px-4 py-10 lg:px-10 lg:py-10">
            <div className='w-full flex flex-row space-x-3 text-sm text-neutral-500 lg:justify-start justify-center'>
                <Link to="#" class="hover:underline">Privacy Policy</Link>
                <Link to="#" class="hover:underline">Terms of Service</Link>
            </div>
        </div>
    </footer>


  )
}

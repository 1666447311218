import React, { createContext, useState } from 'react';
import axios from 'axios';
import BASE_URL from '../config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchloading, setSearchloading] = useState(false);
  const [userToken, setUserToken] = useState('');
  const [autherror, setAutherror] = useState('');
  const [registered, setRegistered] = useState(false);
  
  const login = async (email, password) => {
    try {
      const response = await axios.post(`${BASE_URL}/user/login`, {
        email,
        password,
      });

      if (response.data.message === 'success') {
        // setIsLoggedIn(true);
        // perform additional actions if needed
        // alert(JSON.stringify(response.data.userToken))
        localStorage.clear();
        localStorage.setItem('userToken', JSON.stringify((response.data.userToken)[0]));
        toast('Login successful');
        return 'success';
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error('Login failed: '+ error);
    }
  };

  const signup = async (name, email, password, type) => {
    setIsLoading(true)
    try {
      const response = await axios.post(`${BASE_URL}/user/register`, {
        name,
        email,
        password,
        type,
      });
      if (response.data.message === 'success') {
        // perform additional actions if needed
        setRegistered(true)
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error('Signup failed: '+ error);
    }
    setIsLoading(false)
  };

  const logout = () => {
    // perform logout logic and set isLoggedIn to false
    setIsLoggedIn(false);
    localStorage.clear();
    return 'success'
  };

  const searchproducts = async (txt) => {
    try {
      const userToken = JSON.parse(localStorage.getItem('userToken'));
      setSearchloading(true)
      const response = await axios.post(`${BASE_URL}/products/search`, {
        txt, place: 'home'
      });

      if (response.data.message === 'success') {
        setSearchloading(false)
        return response.data.products;
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error('Failed: '+ error);
    }
    setSearchloading(false)
  };

  const forgotpassword = async (email) => {
    try {
      setIsLoading(true)
      const response = await axios.post(`${BASE_URL}/user/forgotpassword`, {
        email
      });
      if (response.data.message === 'success') {
        // perform additional actions if needed
        setIsLoading(false)
        return 'success';
      } else {
        toast.error(response.data.message)
        setIsLoading(false)
      }
    } catch (error) {
      toast.error('Request failed: '+ error);
    }
  };

  const validatetoken = async (token) => {
    try {
      setIsLoading(true)
      const response = await axios.get(`${BASE_URL}/user/validatetoken/${token}`);
      if (response.data.message === 'success' || response.data.message === 'invalid') {
        // perform additional actions if needed
        setIsLoading(false)
        return response.data.message;
      } else {
        toast.error(response.data.message)
        setIsLoading(false)
      }
    } catch (error) {
      toast.error('Failed to validate reset token: '+ error);
    }
  };

  const resetpassword = async (token, newpassword, confirmpassword) => {
    try {
      setIsLoading(true)
      const response = await axios.post(`${BASE_URL}/user/resetpassword/${token}`, {
        newpassword, confirmpassword
      });
      if (response.data.message === 'success') {
        // perform additional actions if needed
        setIsLoading(false)
        return 'success';
      } else {
        toast.error(response.data.message)
        setIsLoading(false)
      }
    } catch (error) {
      toast.error('Request failed: '+ error);
      setIsLoading(false)
    }
  };

  const verify = async (token) => {
    try {
      setIsLoading(true)
      const response = await axios.get(`${BASE_URL}/user/verify/${token}`);
      if (response.data.message === 'success') {
        // perform additional actions if needed
        setIsLoading(false)
        return response.data.message;
      } else {
        toast.error(response.data.message)
        setIsLoading(false)
        return response.data.message;
      }
    } catch (error) {
      setIsLoading(false)
      toast.error('Request failed: '+ error);
    }
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, registered, isLoading, login, signup, verify, logout, searchproducts, forgotpassword, validatetoken, resetpassword, searchloading }}>
    <ToastContainer 
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark" />
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };

import AppLayout from '../components/AppLayout'
import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../contexts/AppContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import '../styles/addlisting.css'
import { FiArrowRight } from "react-icons/fi";

export default function AddListing() {
  const { productId } = useParams();

  const [name, setName] = useState('test');
  const [shortdescription, setShortdescription] = useState('test');
  const [description, setDescription] = useState('test');
  const [price, setPrice] = useState('10');
  const [error, setError] = useState('');
  const { getproduct, addlisting, updatelisting, isLoading } = useContext(AppContext);
  const [images, setImages] = useState([]);
  const [product, setProduct] = useState('');

  useEffect(() => {

    const fetchProduct = async () => {
      const response = await getproduct({ productid: productId });
      // Update the state with the received products
      if(response !== undefined){
      setProduct(response[0]);
      setName(response[0].name)
      setShortdescription(response[0].shortdescription)
      setDescription(response[0].description)
      setPrice(response[0].price)
      
      const updatedArray = [
        'data:image/png;base64,'+(response[0].image0), // Add response[0].image0 at index 0
        'data:image/png;base64,'+(response[0].image1), // Add response[0].image1 at index 1
        'data:image/png;base64,'+(response[0].image2), // Add response[0].image2 at index 2
        ...images.slice(3), // Add the rest of the elements from the current images array starting from index 3
      ];

      // Update the state with the new array
      setImages(updatedArray);
      }
    };

    if(productId){
      fetchProduct()
    }

  }, [productId])

  const handleImageUpload = (event, index) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const updatedImages = [...images];
        updatedImages[index] = e.target.result;
        setImages(updatedImages);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageRemove = (index) => {
    const updatedImages = [...images];
    updatedImages[index] = null;
    setImages(updatedImages);
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(isLoading === false){
    try {
      if(name !== "" && shortdescription !== "" && description !== "" && price !== "" && images[0] && images[1] && images[2]){
      setError('')
      let response = '';
      if(productId){
        response = await updatelisting(name, shortdescription, description, price, images, productId);
      }else{
        response = await addlisting(name, shortdescription, description,price, images);
      }
      if(response === 'success'){
        navigate('/dashboard')
      }
      }
      else{
          setError('All fields are required')
          return;
      }
    } catch (error) {
          setError('Update failed: '+ error)
    }
  }  
  };

  return (
    <AppLayout>
    <main className='addlisting'>
      <div className='flex justify-between items-center mb-3'>
        <h3 className='text-white text-3xl font-semibold'>Add listing</h3>
      </div>
      <form onSubmit={handleSubmit} method="post" enctype="multipart/form-data" className='space-y-5 lg:w-2/3 md:w-full'>
        <div className="grid-cols-3 grid gap-4">
            
          <div className=''>
            <label for="dropzone-file-0" class="flex flex-col items-center justify-center w-full border-4 border-[#29292D] border-dashed rounded-lg cursor-pointer" style={images[0] ? {backgroundImage: `url('`+images[0]+`')`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'} : { backgroundColor: 'transparent' }}>
                <div class="flex flex-col items-center justify-center py-16 px-3 text-center rounded-lg" style={images[0] && ({backgroundColor:'rgba(0,0,0,0.65)'})}>
                    <svg aria-hidden="true" class="w-10 h-10 mb-3 text-neutral-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                    <p class="mb-2 text-sm text-neutral-400 capitalize"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                    <p class="text-xs text-neutral-600">SVG, PNG, JPG</p>
                </div>
                <input id="dropzone-file-0" type="file" class="hidden" accept='image/*' onChange={(e) => handleImageUpload(e, 0)}/>
            </label>
            {images[0] && (
                <div>
                <button className='text-red-500 text-sm font-medium tracking-wide' onClick={() => handleImageRemove(0)}>Remove</button>
                </div>
            )}
          </div>

          <div className=''>
            <label for="dropzone-file-1" class="flex flex-col items-center justify-center w-full border-4 border-[#29292D] border-dashed rounded-lg cursor-pointer" style={images[1] ? {backgroundImage: `url('`+images[1]+`')`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'} : { backgroundColor: 'transparent' }}>
                <div class="flex flex-col items-center justify-center py-16 px-3 text-center rounded-lg" style={images[1] && ({backgroundColor:'rgba(0,0,0,0.65)'})}>
                    <svg aria-hidden="true" class="w-10 h-10 mb-3 text-neutral-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                    <p class="mb-2 text-sm text-neutral-400 capitalize"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                    <p class="text-xs text-neutral-600">SVG, PNG, JPG</p>
                </div>
                <input id="dropzone-file-1" type="file" class="hidden" accept='image/*' onChange={(e) => handleImageUpload(e, 1)}/>
            </label>
            {images[1] && (
                <div>
                <button className='text-red-500 text-sm font-medium tracking-wide' onClick={() => handleImageRemove(1)}>Remove</button>
                </div>
            )}
          </div>

        <div className=''>
        <label for="dropzone-file-2" class="flex flex-col items-center justify-center w-full border-4 border-[#29292D] border-dashed rounded-lg cursor-pointer" style={images[2] ? {backgroundImage: `url('`+images[2]+`')`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'} : { backgroundColor: 'transparent' }}>
            <div class="flex flex-col items-center justify-center py-16 px-3 text-center rounded-lg" style={images[2] && ({backgroundColor:'rgba(0,0,0,0.65)'})}>
                <svg aria-hidden="true" class="w-10 h-10 mb-3 text-neutral-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                <p class="mb-2 text-sm text-neutral-400 capitalize"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                <p class="text-xs text-neutral-600">SVG, PNG, JPG</p>
            </div>
            <input id="dropzone-file-2" type="file" class="hidden" accept='image/*' onChange={(e) => handleImageUpload(e, 2)}/>
        </label>
        {images[2] && (
            <div>
            <button className='text-red-500 text-sm font-medium tracking-wide' onClick={() => handleImageRemove(2)}>Remove</button>
            </div>
        )}
        </div>

        </div>
        <div>
            <label for="name">Title</label>
            <input type="name" id="name" placeholder="" value={name} onChange={(e) => setName(e.target.value)} required/>
        </div>
        <div>
            <label for="shortdescription">Short description</label>
            <input type="description" id="shortdescription" placeholder="" value={shortdescription} onChange={(e) => setShortdescription(e.target.value)} required/>
        </div>
        <div>
            <label for="description">Description</label>
            <textarea type="description" className='resize-none' id="description" rows={5} placeholder="" onChange={(e) => setDescription(e.target.value)} required>{description}</textarea>
        </div>
        <div className='flex flex-row items-end space-x-5'>
            <div className='w-1/2'>
                <div>
                    <label for="price">Price</label>
                    <input type="number" min="0" id="price" placeholder="" value={price} onChange={(e) => setPrice(e.target.value)} required/>
                </div>
            </div>
            <div className='w-1/2'>
                {error !== "" ? <div className='text-left text-red-500 font-semibold text-md mb-2 tracking-wide'>{error}</div> : <div className='py-4'></div>}
                <button type="submit" class="btn-main w-full uppercase !flex justify-between !py-3.5" disabled={isLoading}><span>Post</span><span>{isLoading === false ? <FiArrowRight size={18}/> : <Bounce animating={isLoading}/>}</span></button>
            </div>
        </div>
      </form>
    </main>
    </AppLayout>
  )
}

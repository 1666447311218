import React from 'react';
import '../styles/home.css'
import { Link } from 'react-router-dom';
import HomeLayout from '../components/HomeLayout';

export default function Home() {
const isLoggedIn = !!localStorage.getItem('userToken');
  return (
    <HomeLayout>
        <main>
        <section className='py-0 lg:py-5 main w-full mx-auto'>
        <div className="relative overflow-hidden">
            <div className="">
            <div className="flex flex-col lg:flex-row relative mx-auto w-full sm:static">
            <div className="w-2/2 lg:w-1/2 pb-10 pt-0 lg:pb-32 lg:pt-0 flex items-center order-2 lg:order-1">
                <div className='p-14 lg:p-0 pl-14 lg:pl-24 ml-2'>
                    <div className='heading mb-5' style={{fontSize:'76px'}}>Sell to the 🌍 today.</div>
                    <p>Unlocking an entire realm of commerce, we empower individuals to effortlessly sell any item with unparalleled ease and at no cost. Our platform provides comprehensive buyer protection and ensures the utmost privacy for sellers.</p>
                    {
                        !isLoggedIn && <Link to="/signup" className='btn-main'>Get started - <b>It’s free</b></Link>
                    }
                </div>
            </div>
            <div className='w-2/2 lg:w-1/2 order-1 lg:order-2'>
                <div className="relative w-11/12 lg:w-10/12 ml-auto mr-auto lg:ml-auto lg:mr-0">
                    <img className="hidden lg:block w-full h-full object-contain right-0 top-0" src="images/home.png" alt="Home" />
                    <img className="block lg:hidden w-full h-full object-contain right-0 top-0 -mt-5" src="images/home-sm.png" alt="Home" />
                </div>
            </div>
            </div>
            </div>
        </div>
        </section>
        <section className='p-12 experience bg-main'>
            <div className='mx-auto text-4xl lg:text-5xl text-neutral-800 font-light text-center lg:text-left'>A new experience.</div>
        </section>
        <section className='feature flex flex-col md:flex-row'>
            <div className='w-2/2 md:w-1/2'>
                <img src='/images/feature-1.png' className='w-full h-full object-cover'/>
            </div>
            <div className='w-2/2 md:w-1/2 p-14 lg:p-20'>
                <h4 className='heading'>Explore a world of sellers near you.</h4>
                <p className='text-neutral-500 text-lg mt-3 mb-10'>JustSell connects people with nearby service providers across the internet.</p>
                { !isLoggedIn && <Link to="/signup" className='btn-main'>Explore sellers</Link> }
            </div>
        </section>
        <section className='feature flex flex-col md:flex-row'>
            <div className='w-2/2 md:w-1/2 p-14 lg:p-20 order-2 md:order-1'>
                <h4 className='heading'>We take security seriously.</h4>
                <h4 className='text-4xl !leading-normal !font-extralight mt-8'>Hi-5s<br/><span className='text-neutral-600'>Encrypted chat<br/>Rating system</span></h4>
                <p className='text-neutral-500 text-lg mt-5 mb-10'>“Hi-5s” are a 6-digit code that a customer gets and share with the sellers once the service is completed that will instantly transfer the payment.</p>
                { !isLoggedIn && <Link to="/signup" className='btn-main'>Get started - <b>It’s free</b></Link> }
            </div>
            <div className='w-2/2 md:w-1/2 order-1 md:order-2'>
                <img src='/images/feature-2.png' className='w-full h-full object-cover'/>
            </div>
        </section>
        <section className='feature flex flex-col md:flex-row'>
            <div className='w-2/2 md:w-1/2'>
                <img src='/images/feature-3.png' className='w-full h-full object-cover'/>
            </div>
            <div className='w-2/2 md:w-1/2 p-14 lg:p-20'>
                <h4 className='heading'>Affordable, so you can just sell.</h4>
                <p className='text-neutral-500 text-lg mt-3 mb-10'>Save more on local sellers that have fewer costs and can offer a lower price than traditional businesses. </p>
                { !isLoggedIn && <Link to="/signup" className='btn-main'>Get started</Link> }
            </div>
        </section>
        <section className='feature flex flex-col md:flex-row'>
            <div className='w-2/2 md:w-1/2 p-14 lg:p-20 order-2 md:order-1 feature4bg'>
                <h4 className='heading !font-light !text-black !mb-10'><span className='font-extrabold'>Just sell.</span> We’ll take care of the rest.</h4>
                { !isLoggedIn && <Link to="/signup" className='btn-main !bg-white'>Get started - <b>It’s free</b></Link> }
            </div>
            <div className='w-2/2 md:w-1/2 order-1 md:order-2'>
                <img src='/images/feature-4.png' className='w-full h-full object-cover'/>
            </div>
        </section>
        </main>
    </HomeLayout>
  )
}

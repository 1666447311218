import '../styles/auth.css'
import AuthLayout from '../components/AuthLayout'
import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useParams, Link } from 'react-router-dom';
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import { MdOutlinePassword } from "react-icons/md"

export default function ResetPassword() {

    const { token } = useParams();
    const [newpassword, setNewPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [passwordreset, setPasswordreset] = useState(false);
    const [error, setError] = useState('');
    const [resp, setResp] = useState('');

    const { resetpassword, validatetoken, isLoading } = useContext(AuthContext);

    useEffect(() => {
        validateToken()
    }, [token]);
    
    const validateToken = async () => {
        const response = await validatetoken(token);
        setResp(response)
        // Update the state with the received products
    }

    const handleSubmit = async (e) => {
      e.preventDefault();
      if(isLoading === false){
      try {
        if(newpassword !== "" && confirmpassword !== ""){
            if (newpassword.length < 6 || confirmpassword.length < 6) {
                setError('Password must have at least 6 characters');
                return;
            }
            else if (newpassword !== confirmpassword) {
                setError('Passwords don\'t match');
                return;
            }
            else{
                setError('')
                const response = await resetpassword(token, newpassword, confirmpassword);
                if(response === 'success'){
                    setPasswordreset(true);
                }
            }    
        }
        else{
            setError('All fields are required')
            return;
        }
      } catch (error) {
            setError('failed: '+ error)
      }
    }  
    };

  return (
    <AuthLayout>
    <main>
        <section className='container px-3 2xl:px-10 xl:px-10 lg:px-10 md:px-7 mx-auto pt-0 2xl:pt-10 xl:pt-10 lg:pt-10 md:pt-5'>
            <div className='flex flex-col lg:flex-row space-y-5 lg:space-y-0 justify-between items-center'>
                <div className='w-full'>
                    <div className='flex'><Link to="/"><img src='/logo.png' className='w-10 h-10'/></Link></div>
                    <div className='text-white text-2xl font-semibold mt-4 leading-relaxed tracking-wide w-full'>Reset password</div>
                </div>
            <form onSubmit={handleSubmit} className='authform w-full max-w-lg p-12 mb-20'>
            {passwordreset === false ? 
            <>
                {/* <h3 className='text-white text-2xl font-semibold text-center mb-2'>Reset your password</h3> */}
                {resp === 'success' || resp === '' ? <h6 className='text-neutral-400 text-md text-center mb-10'>Password must have at least 6 characters</h6> : '' }
                {resp === 'invalid' ? 
                <>
                    <h4 className='text-red-600 text-xl font-semibold text-center mt-10'>Token Expired. Please Try Again!</h4>
                </> : <>
                <div className='fields space-y-7 mb-4'>
                    <div class="relative">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                            <MdOutlinePassword color='#777'/>
                        </div>
                        <input type="password" className='!pl-10' id="newpassword" placeholder="New Password" value={newpassword} onChange={(e) => setNewPassword(e.target.value)} required/>
                    </div>
                    <div class="relative">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                            <MdOutlinePassword color='#777'/>
                        </div>
                        <input type="password" className='!pl-10' id="confirmpassword" placeholder="Confirm New Password" value={confirmpassword} onChange={(e) => setConfirmPassword(e.target.value)} required/>
                    </div>
                </div>
                {error !== "" ? <div className='text-center text-red-500 font-semibold text-md my-3'>{error}</div> : <div className='py-4'></div>}
                <button type="submit" class="btn-main w-full" disabled={isLoading}>{isLoading === false ? 'Submit' : <Bounce animating={isLoading}/>}</button>
                </>}
            </>
            : 
            <>
            <h3 className='text-white text-2xl font-semibold text-center mb-4'>Password Updated Successfully</h3>
            <div className='text-neutral-400 text-center'>Your password has been reset. You can now log in to your account using your new password. If you have any further questions or need assistance, please feel free to contact our support team.</div>
            <div class="btn-main w-full uppercase text-center mt-10">Thank You</div>
            </>
            }
            </form>
            </div>
        </section>
    </main>
    </AuthLayout>
  )
}

import '../styles/header.css'
import { Link,useNavigate } from 'react-router-dom'
import React, { useContext, useState } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { FiArrowRight } from "react-icons/fi";

export default function Header() {

  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [products, setProducts] = useState([]);
  const { searchproducts, searchloading, logout } = useContext(AuthContext);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  // Check if the user is logged in based on the presence of userToken in localStorage
  const isLoggedIn = !!localStorage.getItem('userToken');

  const handleLogout = async () => {
    const response = await logout();
    // Update the state with the received products
    if(response === 'success'){
      navigate('/')
    }
  };

  const handleSearch = async (e) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);

    const response = await searchproducts(searchTerm);
    // Update the state with the received products
    setProducts(response);
  };
  
  return (
    <>
    <nav className={`container pl-4 lg:pl-0 mx-auto ${ isMenuOpen ? 'toggled' : 'pr-3 lg:pr-0' }`}>
    <div class="flex flex-wrap items-center justify-between mx-auto py-8 px-0">
    <div className="flex items-center mr-8"><Link to="/" class="flex items-center space-x-4">
        <img src='/logo.png' className='w-10 h-10'/>
        <span class="hidden lg:block self-center whitespace-nowrap nav-title uppercase">Just<br/>Sell</span>
    </Link></div>
    <div class="flex md:hidden">
        <button data-collapse-toggle="navbar-cta" type="button" className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden bg-transparent focus:bg-transparent" aria-controls="navbar-cta" aria-expanded={isMenuOpen} onClick={toggleMenu} >
            <span class="sr-only">Open main menu</span>
            <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
        </button>
    </div>
    <div
        className={`items-center justify-between lg:flex lg:flex-1 md:contents md:order-1 w-full ${ isMenuOpen ? '' : 'hidden' }`}
        id="navbar-cta"
      >
        <div className='lg:flex md:flex flex-1'>
          <div className='sm-toggler'>
            <div className='w-full flex flex-row justify-end pt-0.5 pr-0.5'>
              <button data-collapse-toggle="navbar-cta" type="button" className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden bg-transparent focus:bg-transparent" aria-controls="navbar-cta" aria-expanded={isMenuOpen} onClick={toggleMenu} >
                  <span class="sr-only">Open main menu</span>
                  <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011" clip-rule="evenodd"></path></svg>
              </button>
            </div>
          </div>


          <div class="relative flex-1 w-full pl-5 md:pl-10 lg:pl-10 pr-5 md:pr-0 lg:pr-0 mt-4 lg:mt-0 md:mt-0">
          <div className='relative w-full lg:w-3/5 mx-auto'>
          <div class="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
              {searchloading === true ? <Spinner size={14} className='text-neutral-300'/> : <svg class="w-4 h-4 rotate-90 text-neutral-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>}
              <span class="sr-only">Search icon</span>
          </div>
          <input type="text" id="search-navbar" class="block w-full p-2.5 pl-10 text-sm flex-1" placeholder="Search..." value={search} onChange={handleSearch}/>
          {products.length > 0 && search != "" && (
            <div className="absolute z-50 mt-1 p-3 space-y-3 w-full bg-neutral-900 rounded-md">
              {products.slice(0, 3).map((product, index) => (
                <div key={index} className='flex-1 border-b border-b-neutral-800 pb-3'>
                <Link onClick={() => setSearch('')} to={`/products?product=${product.productid}`}>
                <div className="hover:bg-neutral-800 rounded-lg flex flex-row space-x-3">
                    <img class="rounded-lg object-cover w-24 h-24" src={`data:image/png;base64,${product.image0}`} alt={product.name} />
                    <div class="px-5 flex flex-1 flex-col justify-center">
                        <div>
                        <div class="flex items-center justify-between mb-2">
                            <h5 class="mb-0 text-xl font-semibold text-white">{product.name}</h5>
                            <span class="text-md font-medium text-neutral-300">${product.price}</span>
                        </div>
                        <p class="text-xs text-neutral-400">{product.shortdescription}</p>
                        </div>
                    </div>
                </div>
                </Link>
                </div>
              ))}
              <Link onClick={() => setSearch('')} to={`/products?search=${search}`}>
              <div className="hover:bg-neutral-800 rounded flex justify-center p-3">
                <div className='text-md text-neutral-400 font-semibold flex flex-row space-x-2 items-center'><span>Found {products.length} results</span> <FiArrowRight/></div>
              </div>
              </Link>
            </div>
          )}
          </div>
          </div>
          
          <div className='sm-toggler'>
            <div className='w-full p-6 text-white text-2xl flex flex-col space-y-5 text-right'>
              {isLoggedIn ? (
              // User is logged in
              <>
              <div><Link to="/">Dashboard</Link></div>
              <div><Link to="/" onClick={handleLogout}>Logout</Link></div>
              </>
              ) : (
              // User is not logged in
              <>
              <div><Link to="/">Home</Link></div>
              <div><Link to="/login">Log in</Link></div>
              <div><Link to="/signup">Get Started</Link></div>
              <div><Link to="/products">Explore Products</Link></div>
              </>
              )}
            </div>
          </div>

          <div className='flex flex-col md:flex-row justify-end lg:ml-8 md:ml-8 sm:ml-0 nav-buttons'>
              {isLoggedIn ? (
              // User is logged in
              <>
                <div><Link to="/dashboard" className="btn-main-alt mr-4">Dashboard</Link></div>
                <div><Link to="/" onClick={handleLogout} className="btn-main">Logout</Link></div>
              </>
            ) : (
              // User is not logged in
              <>
                <div><Link to="/login" className="btn-main-alt !bg-transparent !text-neutral-400 mr-4">Log in</Link></div>
                <div><Link to="/signup" className="btn-main !px-8">Sign Up</Link></div>
              </>
            )}
          </div>

      </div>
    </div>
    </div>
    </nav>

    </>
  )
}

import AppLayout from '../components/AppLayout'
import React, { Fragment, useEffect, useState, useContext } from 'react';
import { AppContext } from '../contexts/AppContext';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import '../styles/orders.css'
import { BsArrowRightShort, BsArrowUpRightSquare } from 'react-icons/bs';
import { RxCross2 } from "react-icons/rx";
import { HiStar } from "react-icons/hi";
import { Dialog, Transition } from '@headlessui/react'
import { FiArrowRight } from 'react-icons/fi';


export default function Orders() {

    const [error, setError] = useState('');
    const [orders, setOrders] = useState([]);
    const [order, setOrder] = useState('');
    const [product, setProduct] = useState('');
    const [modalopen, setModalopen] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [isLoading3, setIsLoading3] = useState(false);
    const [rating, setRating] = useState(0);
    const [review, setReview] = useState('');

    const { submitreview, getmyorders, getorder, isLoading } = useContext(AppContext);

    const navigate = useNavigate();

    const fetchMyOrders = async () => {
      const response = await getmyorders();
      setOrders(response);
    }

    useEffect(() => {
      fetchMyOrders();
    }, []);
    
    const fetchOrder = async (orderid) => {
        const response = await getorder({ orderid });
        setOrder((response.order));
        setProduct((response.product));
        setIsLoading3(false)
    }

    const openreviewmodal = async (orderid, exists) => {
      setOrder(orderid);
      if(orderid != ""){
        if(exists === 'yes'){
          setIsLoading3(true)
        }
        else{
        }
        setRating(0)
        fetchOrder(orderid)
        setReview('')
        setError('')
        setModalopen(true)
      }
    }

    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsLoading2(true)
      if(isLoading === false && isLoading2 === false && order != ""){
      try {
        if(rating > 0){
        setError('')
        const response = await submitreview(rating, review, order.orderid);
        if(response === 'success'){
          fetchMyOrders()
          setRating(0)
          setReview('')
          setError('')
          setModalopen(false)
          setIsLoading2(false)
        }
        }
        else{
            setIsLoading2(false)
            setError('Rating is required')
            return;
        }
      } catch (error) {
            setError('Update failed: '+ error)
      }
      }  
      setIsLoading2(false)
    };

  return (
    <AppLayout>
    <main>
      <div className='flex justify-between items-center mb-3'>
        <h3 className='text-white text-3xl font-semibold'>Orders</h3>
      </div>
      {
        isLoading === false ? 
          (orders && orders.length > 0) ?
          <div className='space-y-3'>
          {
            orders.map((order, index) => {
              const formattedDate = new Date(order.addedat).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" });
              return(
                <div key={index} className='block lg:flex items-center justify-between order px-5'>
                  <div className='grid grid-cols-2 lg:grid-cols-4 gap-4 justify-between w-full lg:w-2/3 py-5'>
                    <div><h6>Name</h6><p className='text-md flex items-center'>{order.productname} <Link className='text-blue-500 ml-2' to={`/product/${order.productid}`}><BsArrowUpRightSquare size={18}/></Link></p></div>
                    <div><h6>Date</h6><p>{formattedDate}</p></div>
                    <div><h6>Amount</h6><p>${order.amount}</p></div>
                    {order.redeemed === 1 && 
                    <div><h6>Feedback</h6><p>{order.rating > 0 ? <div className='flex flex-row items-center'>{Array(5).fill(<div>Element</div>).map((element, index) => (
                      index < order.rating ? <HiStar size={18} color='#EDAE49' key={index}/> : <HiStar size={18} color='#1E293B' key={index}/>
                    ))} <span onClick={() => openreviewmodal(order.orderid, 'yes')} className='text-blue-400 hover:text-blue-600 flex items-center cursor-pointer ml-2'><BsArrowUpRightSquare size={18}/></span></div> : <>{<span onClick={() => openreviewmodal(order.orderid, 'no')} className='text-blue-400 hover:text-blue-600 flex items-center cursor-pointer'>Submit Feedback <BsArrowRightShort size={20}/></span>}</>}</p></div>
                    }
                  </div>
                  <div className='flex w-full lg:w-auto pb-5 lg:pt-5 md:pt-0'>
                    <div>{order.redeemed === 0 ? <button className='btn-refund'>Instant Refund</button> : <>{<button className='btn-redeemed'>Redeemed</button>}</>}</div>
                    <div><Link to={`/order/${order.orderid}`} className='btn-open'>Open Hi-5</Link></div>
                  </div>
                </div>
              )
            }) 
          }
          </div>
          : <div className='text-neutral-200 text-center text-xl font-medium tracking-wide py-10'>No Orders Found</div>
        : 
        <div className='flex justify-center w-full py-12'><Spinner color='#CCCCCC' size={24}/></div>
        }
    </main>
    
    <Transition.Root show={modalopen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setModalopen}>
          <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          >
          <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
              <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              >
              <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-lg md:px-4 lg:max-w-lg">
                  <div className="relative w-full items-center overflow-hidden bg-white rounded-lg px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-4 lg:p-4">
                  <div className='w-full flex flex-row justify-between'>
                    <h5 className='text-lg font-semibold text-neutral-800'>Feedback</h5>
                  <button
                      type="button"
                      className="text-gray-400 hover:text-gray-500"
                      onClick={() => setModalopen(false)}>
                      <span className="sr-only">Close</span>
                      <RxCross2 className="h-6 w-6" aria-hidden="true" />
                  </button>
                  </div>
                  <hr className='my-4'/>
                  { isLoading3 === false ?
                  <>
                  { order.rating > 0 ?
                  <div>
                    <div className="w-full flex items-center mb-4">
                    {Array(5).fill(<div>Element</div>).map((element, index) => (
                      <button
                      key={index}
                      type="button"
                      className={`text-3xl ${
                        index <= order.rating ? 'text-yellow-500' : 'text-gray-300'
                      }`}>
                      &#9733;
                    </button>
                    ))}
                    </div>
                    <div className='resize-none block p-3 w-full text-sm text-neutral-900 rounded border border-gray-200 focus:border-gray-400'>{order.review}</div>
                  </div>
                  :
                  <form onSubmit={handleSubmit}  className=''>
                    <div className="w-full flex items-center mb-4">
                    {[1, 2, 3, 4, 5].map((value) => (
                      <button
                        key={value}
                        type="button"
                        className={`text-3xl ${
                          rating >= value ? 'text-yellow-500' : 'text-gray-300'
                        }`}
                        onClick={() => setRating(value)}
                      >
                        &#9733;
                      </button>
                    ))}
                    </div>
                    <textarea type="review" className='resize-none block p-3 w-full text-sm text-neutral-900 rounded border border-gray-200 focus:border-gray-400' id="review" rows={5} placeholder="Write your review here.." onChange={(e) => setReview(e.target.value)}>{review}</textarea>
                    <div className='mt-7'>
                      {error !== "" ? <div className='text-left text-red-500 font-semibold text-md mb-2 tracking-wide'>{error}</div> : <div className='py-4'></div>}
                      <button type="submit" class="btn-main w-full uppercase !flex items-center justify-between hover:!shadow-none !py-3.5" disabled={isLoading2}><span>Submit</span><span>{isLoading2 === false ? <FiArrowRight size={18}/> : <Spinner animating={isLoading2}/>}</span></button>
                    </div>
                  </form>
                  }
                  </>
                  : 
                  <div className='flex justify-center w-full py-12'><Spinner color='#CCCCCC' size={24}/></div>
                  }
                  </div>
              </Dialog.Panel>
              </Transition.Child>
          </div>
          </div>
      </Dialog>
    </Transition.Root>
    </AppLayout>
  )
}

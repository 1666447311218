import '../styles/appheader.css'
import { Link, useLocation, NavLink } from 'react-router-dom';
import React, { useContext, useRef, useState } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { RiSearchLine } from 'react-icons/ri';
import { HiOutlineChatBubbleBottomCenterText } from 'react-icons/hi2';
import { IoBagCheckOutline } from 'react-icons/io5';
import { IoSettingsOutline } from 'react-icons/io5';
import { CiLogout } from 'react-icons/ci';
import { IoWalletOutline } from 'react-icons/io5';
import { IoStorefrontOutline } from 'react-icons/io5';
import { BsBagCheck, BsBoxes } from 'react-icons/bs';

export default function AuthHeader() {

const location = useLocation();
const pathname = location.pathname;
const parts = pathname.split("/");
const pagetitle = parts[parts.length - 1];
    
const [isSidebarOpen, setIsSidebarOpen] = useState(false);
const sidebarRef = useRef(null);
const { logout } = useContext(AuthContext);
const userToken = JSON.parse(localStorage.getItem('userToken'));

const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
}
  
  return (
    <>
    <div className='flex lg:hidden md:hidden items-center space-x-2 mt-0 ml-2'>
        <button
        id="logo-sidebar-toggle"
        data-drawer-target="logo-sidebar"
        data-drawer-toggle="logo-sidebar"
        aria-controls="logo-sidebar"
        type="button"
        className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        onClick={handleSidebarToggle}
        >
        <span className="sr-only">Open sidebar</span>
        <svg
            className={`w-8 h-8 ${isSidebarOpen ? 'text-white' : 'text-gray-500'}`}
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
            />
        </svg>
        </button>
    </div>

    <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${
            isSidebarOpen ? 'translate-x-0' : '-translate-x-full sm:translate-x-0'
        }`}
        aria-label="Sidebar"
        ref={sidebarRef}
      >
        <div class="h-full flex flex-col justify-between overflow-y-auto border-r border-neutral-800 relative">
            <div className='block lg:hidden absolute right-3 top-3'><button
                id="logo-sidebar-toggle"
                data-drawer-target="logo-sidebar"
                data-drawer-toggle="logo-sidebar"
                aria-controls="logo-sidebar"
                type="button"
                className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden"
                onClick={handleSidebarToggle}
                >
                <svg
                    className={`w-8 h-8 text-white`}
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                    />
                </svg>
                </button>
                </div>
            <div>
            <Link to="/dashboard" class="flex items-center space-x-4 py-5 pl-6">
                <img src='/logo.png' className='w-11 h-11'/>
                {userToken.type === 'Seller' ? <img src='/images/sell.png' className='w-11 h-11'/> : <img src='/images/shop.png' className='w-11 h-11'/>}
            </Link>
            <ul class="sidebar-menu space-y-2 py-3 px-3">
                {userToken.type === 'Seller' && <>
                <li>
                <NavLink activeClassName="active" isActive={(match, location) => match || location.pathname === '/add-listing'} to="/dashboard">
                    <IoStorefrontOutline/>
                    <span>Store</span>
                </NavLink>
                </li>
                <li>
                <NavLink activeClassName="active" to="/sales">
                    <BsBoxes/>
                    <span>Sales</span>
                </NavLink>
                </li>
                <li>
                <NavLink activeClassName="active" to="/redeem">
                    <BsBagCheck/>
                    <span>Redeem</span>
                </NavLink>
                </li>
                <li>
                <NavLink activeClassName="active" to="/wallet">
                    <IoWalletOutline/>
                    <span>Wallet</span>
                </NavLink>
                </li>
                </>}
                {userToken.type === 'Buyer' && <>
                <li>
                <NavLink activeClassName="active" to="/dashboard">
                    <RiSearchLine/>
                    <span>Search</span>
                </NavLink>
                </li>
                <li>
                <NavLink activeClassName="active" to="/orders">
                    <IoBagCheckOutline/>
                    <span>Orders</span>
                </NavLink>
                </li>
                </>}
                <li>
                <NavLink activeClassName="active" to="/chat">
                    <HiOutlineChatBubbleBottomCenterText/>
                    <span>Chats</span>
                </NavLink>
                </li>
                <li>
                <NavLink activeClassName="active" to="/settings">
                    <IoSettingsOutline/>
                    <span>Settings</span>
                </NavLink>
                </li>
            </ul>
            </div>
            <ul class="sidebar-menu space-y-2 py-5 px-3 border-t border-neutral-800">
            <li>
            <Link className='hover:!bg-red-500' to="/" onClick={logout}>
                <CiLogout/>
                <span>Log out</span>
            </Link>
            </li>
            </ul>
        </div>
    </aside>

    </>
  )
}

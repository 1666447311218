import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import BASE_URL from '../config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchloading, setSearchloading] = useState(false);
  const [apperror, setApperror] = useState('');
  const [userToken, setuserToken] = useState('');
  
  useEffect(() => {
    setuserToken(JSON.parse(localStorage.getItem('userToken')));
  }, []);

  const addlisting = async (name, shortdescription, description, price, images) => {
    try {
      const userToken = JSON.parse(localStorage.getItem('userToken'));
      setIsLoading(true)
      const response = await axios.post(`${BASE_URL}/products/add`, {
        name, shortdescription, description, price, images, 
        userid: userToken.userid
      });

      if (response.data.message === 'success') {
        toast.success('Product Added Successfully!');
        setIsLoading(false)
        return 'success';
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error('Failed: '+ error);
    }
    setIsLoading(false)
  };

  const updatelisting = async (name, shortdescription, description, price, images, productid) => {
    try {
      const userToken = JSON.parse(localStorage.getItem('userToken'));
      setIsLoading(true)
      const response = await axios.post(`${BASE_URL}/products/update`, {
        name, shortdescription, description, price, images, productid,
        userid: userToken.userid
      });

      if (response.data.message === 'success') {
        toast.success('Product Updated Successfully!');
        setIsLoading(false)
        return 'success';
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error('Failed: '+ error);
    }
    setIsLoading(false)
  };

  const getmylistings = async () => {
    try {
      const userToken = JSON.parse(localStorage.getItem('userToken'));
      setIsLoading(true)
      const response = await axios.post(`${BASE_URL}/products/mylistings`, {
        userid: userToken.userid
      });

      if (response.data.message === 'success') {
        setIsLoading(false)
        return response.data.products;
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error('Failed: '+ error);
    }
    setIsLoading(false)
  };

  const deletelisting = async ({productid}) => {
    try {
      const userToken = JSON.parse(localStorage.getItem('userToken'));
      setIsLoading(true)
      const response = await axios.post(`${BASE_URL}/products/delete`, {
        productid,
        userid: userToken.userid
      });

      if (response.data.message === 'success') {
        setIsLoading(false)
        toast.success('Product Deleted Successfully!');
        return 'success';
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error('Failed: '+ error);
    }
    setIsLoading(false)
  };

  const getproducts = async () => {
    try {
      setIsLoading(true)
      const response = await axios.post(`${BASE_URL}/products/all`);

      if (response.data.message === 'success') {
        setIsLoading(false)
        return response.data.products;
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error('Failed: '+ error);
    }
    setIsLoading(false)
  };

  const getproduct = async ({productid}) => {
    try {
      const userToken = JSON.parse(localStorage.getItem('userToken'));
      setIsLoading(true)
      const response = await axios.post(`${BASE_URL}/products/get`, {
        productid
      });

      if (response.data.message === 'success') {
        setIsLoading(false)
        return response.data.product;
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error('Failed: '+ error);
    }
    setIsLoading(false)
  };

  const getproductreviews = async ({productid}) => {
    try {
      // setIsLoading(true)
      const response = await axios.post(`${BASE_URL}/products/getreviews`, {
        productid
      });

      if (response.data.message === 'success') {
        // setIsLoading(false)
        return response.data.reviews;
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error('Failed: '+ error);
    }
    // setIsLoading(false)
  };

  const searchproducts = async (txt) => {
    try {
      setSearchloading(true)
      const response = await axios.post(`${BASE_URL}/products/search`, {
        txt, place: 'dashboard'
      });

      if (response.data.message === 'success') {
        setSearchloading(false)
        return response.data.products;
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error('Failed: '+ error);
    }
    setSearchloading(false)
  };

  const placetemporder = async (productid) => {
    try {
      const userToken = JSON.parse(localStorage.getItem('userToken'));
      setIsLoading(true)
      const response = await axios.post(`${BASE_URL}/orders/add`, {
        productid, 
        'userid': userToken.userid
      });

      if (response.data.message === 'success') {
        toast.success('Order Placed Successfully!');
        setIsLoading(false)
        return 'success';
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error('Failed: '+ error);
    }
    setIsLoading(false)
  };

  const getmyorders = async () => {
    try {
      const userToken = JSON.parse(localStorage.getItem('userToken'));
      setIsLoading(true)
      const response = await axios.post(`${BASE_URL}/orders/myorders`, {
        userid: userToken.userid
      });

      if (response.data.message === 'success') {
        setIsLoading(false)
        return response.data.orders;
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error('Failed: '+ error);
    }
    setIsLoading(false)
  };

  const getorder = async ({orderid}) => {
    try {
      const userToken = JSON.parse(localStorage.getItem('userToken'));
      setIsLoading(true)
      const response = await axios.post(`${BASE_URL}/orders/get`, {
        userid: userToken.userid,
        orderid
      });

      if (response.data.message === 'success') {
        setIsLoading(false)
        return response.data;
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error('Failed: '+ error);
    }
    setIsLoading(false)
  };

  const submitreview = async (rating, review, orderid) => {
    try {
      const userToken = JSON.parse(localStorage.getItem('userToken'));
      setIsLoading(true)
      const response = await axios.post(`${BASE_URL}/orders/addreview`, {
        rating, review, orderid, 
        userid: userToken.userid
      });

      if (response.data.message === 'success') {
        toast.success('Feedback Added Successfully!');
        setIsLoading(false)
        return 'success';
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error('Failed: '+ error);
    }
    setIsLoading(false)
  };

  const getchatuser = async ({userid}) => {
    try {
      const userToken = JSON.parse(localStorage.getItem('userToken'));
      setIsLoading(true)
      const response = await axios.post(`${BASE_URL}/chat/getuser`, {
        userid
      });

      if (response.data.message === 'success') {
        setIsLoading(false)
        return response.data.user;
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error('Failed: '+ error);
    }
    setIsLoading(false)
  };

  const getmysales = async () => {
    try {
      const userToken = JSON.parse(localStorage.getItem('userToken'));
      setIsLoading(true)
      const response = await axios.post(`${BASE_URL}/orders/mysales`, {
        userid: userToken.userid
      });

      if (response.data.message === 'success') {
        setIsLoading(false)
        return response.data.sales;
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error('Failed: '+ error);
    }
    setIsLoading(false)
  };

  const verifycode = async (code) => {
    try {
      const userToken = JSON.parse(localStorage.getItem('userToken'));
      setIsLoading(true)
      const response = await axios.post(`${BASE_URL}/orders/verify`, {
        userid: userToken.userid,
        code
      });

      if (response.data.message === 'success') {
        setIsLoading(false)
        return response.data;
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error('Failed: '+ error);
    }
    setIsLoading(false)
  };

  const redeemorder = async (code) => {
    try {
      const userToken = JSON.parse(localStorage.getItem('userToken'));
      setIsLoading(true)
      const response = await axios.post(`${BASE_URL}/orders/redeem`, {
        userid: userToken.userid,
        code
      });

      if (response.data.message === 'success') {
        toast.success('Order Redeemed Successfully!');
        setIsLoading(false)
        return response.data.message;
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error('Failed: '+ error);
    }
    setIsLoading(false)
  };

  const getmywallet = async () => {
    try {
      const userToken = JSON.parse(localStorage.getItem('userToken'));
      setIsLoading(true)
      const response = await axios.post(`${BASE_URL}/orders/wallet`, {
        userid: userToken.userid
      });

      if (response.data.message === 'success') {
        setIsLoading(false)
        return response.data.sales;
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error('Failed: '+ error);
    }
    setIsLoading(false)
  };

  const getmywalletstats = async () => {
    try {
      const userToken = JSON.parse(localStorage.getItem('userToken'));
      setIsLoading(true)
      const response = await axios.post(`${BASE_URL}/orders/walletstats`, {
        userid: userToken.userid
      });

      if (response.data.message === 'success') {
        setIsLoading(false)
        return response.data.stats;
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error('Failed: '+ error);
    }
    setIsLoading(false)
  };

  const connectstripe = async () => {
    try {
      const userToken = JSON.parse(localStorage.getItem('userToken'));
      setIsLoading(true)
      const response = await axios.post(`${BASE_URL}/orders/walletstats`, {
        userid: userToken.userid
      });

      if (response.data.message === 'success') {
        setIsLoading(false)
        return response.data.stats;
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error('Failed: '+ error);
    }
    setIsLoading(false)
  };
  
  const updateprofile = async (name, email) => {
    try {
      const userToken = JSON.parse(localStorage.getItem('userToken'));
      setIsLoading(true)
      const response = await axios.post(`${BASE_URL}/user/update`, {
        name,
        email,
        userid: userToken.userid
      });

      if (response.data.message === 'success') {
        localStorage.setItem('userToken', JSON.stringify((response.data.userToken)[0]));
        toast.success('Profile Updated Successfully!');
        setIsLoading(false)
        return 'success';
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error('Request failed: '+ error);
    }
    setIsLoading(false)
  };
  
  const updateprofilepicture = async (image) => {
    try {
      const userToken = JSON.parse(localStorage.getItem('userToken'));
      setIsLoading(true)
      const response = await axios.post(`${BASE_URL}/user/updateprofilepicture`, {
        image,
        userid: userToken.userid
      });

      if (response.data.message === 'success') {
        localStorage.setItem('userToken', JSON.stringify((response.data.userToken)[0]));
        toast.success('Image Updated Successfully!');
        setIsLoading(false)
        return 'success';
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      toast.error('Request failed: '+ error);
    }
    setIsLoading(false)
  };
  
  const updatepassword = async (oldpassword, newpassword, confirmpassword) => {
    try {
        if (newpassword === confirmpassword) {
          const userToken = JSON.parse(localStorage.getItem('userToken'));
          setIsLoading(true)
          const response = await axios.post(`${BASE_URL}/user/updatepassword`, {
            oldpassword, newpassword, confirmpassword,
            userid: userToken.userid
          });

          if (response.data.message === 'success') {
            toast.success('Password Updated Successfully!');
            setIsLoading(false)
            return 'success';
          } else {
            toast.error(response.data.message)
          } 
        
        }else {
          toast.error('Passwords don\'t match')
        }
    } catch (error) {
      toast.error('Request failed: '+ error);
    }
    setIsLoading(false)
  };

  const logout = () => {
    setIsLoggedIn(false);
    localStorage.clear();
  };

  return (
    <AppContext.Provider value={{ userToken, isLoading, addlisting, updatelisting, getmylistings, deletelisting, getproducts, getproduct, getproductreviews, placetemporder, getmyorders, getorder, submitreview, getchatuser, getmysales, verifycode, redeemorder, getmywallet, getmywalletstats, connectstripe, updateprofile, updateprofilepicture, updatepassword, searchproducts, searchloading }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };

import AppLayout from '../components/AppLayout'
import React, { useState, useContext, useRef, useEffect } from 'react';
import { AppContext } from '../contexts/AppContext';
import { Link } from 'react-router-dom';
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import '../styles/settings.css'
import { HiArrowNarrowRight } from 'react-icons/hi';
import ProfilePicture from "@dsalvagni/react-profile-picture"
import "@dsalvagni/react-profile-picture/dist/ProfilePicture.css"

export default function Settings() {

    const userToken = JSON.parse(localStorage.getItem('userToken'));
    const [name, setName] = useState(userToken.name);
    const [email, setEmail] = useState(userToken.email);
    const [error, setError] = useState('');
    const [error2, setError2] = useState('');
    const [imageset, setImageSet] = useState(false);
    const [editimage, setEditimage] = useState(false);
    const profilePictureRef = useRef(null);

    const { updateprofile, updateprofilepicture, isLoading } = useContext(AppContext);

    useEffect(() => {
        if(userToken.image !== null && userToken.image !== ''){
          const PP = profilePictureRef.current;
        }
    }, [userToken])

    const handleUpload = async () => {
      const PP = profilePictureRef.current;
      const imageData = PP.getData();
      const file = imageData.file;
      const imageAsDataURL = PP.getImageAsDataUrl();

      if(isLoading === false){
      try {
        if(imageAsDataURL !== ""){
        setError2('')
        const response = await updateprofilepicture(imageAsDataURL);
        if(response == 'success'){
          setImageSet(false)
          setEditimage(false)
        }
        }
        else{
            setError2('An error occurred')
            return;
        }
      } catch (error) {
            setError2('Update failed: '+ error)
      }
      }  
  
      //add here the upload logic...
    }

    const handleSubmit = async (e) => {
      e.preventDefault();
      if(isLoading === false){
      try {
        if(name !== "" && email !== ""){
        setError('')
        const response = await updateprofile(name, email);
        }
        else{
            setError('All fields are required')
            return;
        }
      } catch (error) {
            setError('Update failed: '+ error)
      }
      }  
    };

  return (
    <AppLayout>
    <main className='settings'>
      <div className='flex justify-between items-center mb-3'>
        <h3 className='text-white text-3xl font-semibold'>Profile Settings</h3>
      </div>
      <div className='mb-5 mt-10'>
        <label>Profile Picture</label>
        <div className='flex space-x-7'>
          <div style={{backgroundColor: '#17171B'}} className='mt-3 border border-neutral-800 p-5'>
            {editimage == true ? 
            <ProfilePicture ref={profilePictureRef} onImageLoaded={() => setImageSet(true)} onImageRemoved={() => setImageSet(false)} cropSize={350} frameFormat='circle' />
            : <img className='rounded-full w-[350px] h-[350px]' src={`data:image/png;base64,${userToken.image}`}/> }
          </div>
          <div className='pt-3 space-y-3'>
            <div className='text-neutral-400 text-sm'>A circle image shown on your listings and profile.</div>
            {error2 !== "" && <div className='text-center text-red-500 font-semibold text-md'>{error2}</div>}
            <button type='button' className={`btn-main !py-3 !px-8 ${editimage == true && '!bg-transparent !text-red-500 border !border-red-500'}`} onClick={() => setEditimage(!editimage)} disabled={isLoading}>{editimage == true ? 'Cancel' : 'Edit Picture'}</button>
            <div>{ imageset == true && <button type='button' className='btn-main !py-3 !px-8' onClick={handleUpload} disabled={isLoading}>{isLoading === false ? 'Set Picture' : <Bounce animating={isLoading}/>}</button> }</div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit} className='space-y-5 lg:w-1/2 md:w-full'>
        <div>
            <label for="name">Your Name</label>
            <input type="name" id="name" placeholder="John" value={name} onChange={(e) => setName(e.target.value)} required/>
        </div>
        <div>
            <label for="email">Email Address</label>
            <input type="email" id="email" placeholder="johndoe@example.com" value={email} onChange={(e) => setEmail(e.target.value)} required/>
        </div>
        {error !== "" ? <div className='text-center text-red-500 font-semibold text-md'>{error}</div> : <div className='py-3'></div>}
        <div className='block lg:flex flex-row space-x-0 lg:space-x-3 space-y-5 lg:space-y-0'>
          <div className='lg:w-1/2 w-full'><button type="submit" class="btn-main w-full uppercase" disabled={isLoading}>{isLoading === false ? 'Save Changes' : <Bounce animating={isLoading}/>}</button></div>
          <div className='lg:w-1/2 w-full'><Link to={"/update-password"} class="btn-main-inverse w-full uppercase !flex flex-row items-center justify-center">Update Password <HiArrowNarrowRight className='ml-2' size={16} /></Link></div>
        </div>
      </form>
    </main>
    </AppLayout>
  )
}

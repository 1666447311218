import AppLayout from '../components/AppLayout'
import React, { useState, useContext } from 'react';
import { AppContext } from '../contexts/AppContext';
import { Link, useNavigate } from 'react-router-dom';
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import { HiArrowNarrowRight } from "react-icons/hi";
import {TbExternalLink} from "react-icons/tb"
import OtpInput from 'react-otp-input';
import '../styles/orders.css'
import { BsCheck2All } from 'react-icons/bs';

export default function Redeem() {
    const { verifycode, redeemorder, isLoading } = useContext(AppContext);
    const [order, setOrder] = useState('');
    const [code, setCode] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();

    const verifyCode = async (e) => {
        if(isLoading === false && code.length === 6){
            try {
                setError('')
                const response = await verifycode(code);
                if(response.message === 'success'){
                    setOrder(response.order[0])
                }
                else{
                    setOrder('')
                    setError(response.message)
                }
            } catch (error) {
                setError('Failed: '+ error)
            }
        }
    }

    const redeemOrder = async (e) => {
        if(isLoading === false && code.length === 6 && order !== ""){
            try {
                setError('')
                const response = await redeemorder(code);
                if(response === 'success'){
                    navigate('/sales')
                }
                else{
                    setError(response.message)
                }
            } catch (error) {
                setError('Failed: '+ error)
            }
        }
    }

  return (
    <AppLayout>
    <main>
        <div className='flex justify-between items-center mb-3'>
            <h3 className='text-white text-3xl font-semibold'>Redeem</h3>
        </div>
        <div className='mt-10'>
            <div className='lg:w-1/2 md:w-2/2 mx-auto font-semibold text-neutral-200 mb-6 text-center text-2xl'>Enter Hi-5 Code</div>
            <div className='flex flex-col space-y-10 items-center justify-center'>   
            <OtpInput
                value={code}
                onChange={setCode}
                numInputs={6}
                renderSeparator={<span></span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={'codeinput'}
                />
            {error}
            <button onClick={() => verifyCode()} disabled={code.length === 6 ? false : true} className='btn-main !flex items-center !font-semibold disabled:opacity-50'>{isLoading === true ? <Bounce animating={true} className='text-neutral-800 my-1.5 mx-3' size={18} /> : <>Verify <HiArrowNarrowRight className='ml-1 text-neutral-800' size={18} /></>}</button>
            </div>
        </div>
            {
            order !== "" ? 
            <>
                <div className='lg:flex items-center justify-between bg-black rounded-md p-1 md:p-3 lg:p-5 mt-10'>
                <table class="w-full text-sm text-left text-white">
                    <tbody>
                        <tr class="border border-neutral-900">
                            <th scope="row" class="px-3 py-3 lg:px-6 lg:py-4 font-medium border-b border-neutral-950 bg-neutral-900">
                                Product Name:
                            </th>
                            <td class="px-3 py-3 lg:px-6 lg:py-4 flex items-center">
                                {order.productname} <Link className='text-blue-500 ml-2' to={`/product/${order.productid}`}><TbExternalLink size={18}/></Link>
                            </td>
                        </tr>
                        <tr class="border border-neutral-900">
                            <th scope="row" class="px-3 py-3 lg:px-6 lg:py-4 font-medium border-b border-neutral-950 bg-neutral-900">
                                Product Short Description:
                            </th>
                            <td class="px-3 py-3 lg:px-6 lg:py-4">
                                {order.productshortdescription}
                            </td>
                        </tr>
                        <tr class="border border-neutral-900">
                            <th scope="row" class="px-3 py-3 lg:px-6 lg:py-4 font-medium border-b border-neutral-950 bg-neutral-900">
                                Product Price:
                            </th>
                            <td class="px-3 py-3 lg:px-6 lg:py-4">
                                ${order.productprice}
                            </td>
                        </tr>
                        <tr class="border border-neutral-900">
                            <th scope="row" class="px-3 py-3 lg:px-6 lg:py-4 font-medium border-b border-neutral-950 bg-neutral-900">
                                Order Date:
                            </th>
                            <td class="px-3 py-3 lg:px-6 lg:py-4">
                                { new Date(order.addedat).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" }) }
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
                <div className='mt-10 text-center text-neutral-300'>
                    <button onClick={() => redeemOrder()} className='btn-main !flex items-center !font-bold disabled:opacity-50 w-1/2 mx-auto justify-center !py-5'>{isLoading === true ? <Bounce animating={true} className='my-1.5 mx-3' size={18} /> : <>REDEEM <BsCheck2All className='ml-1' size={20} /></>}</button>
                </div>
            </>
            :
            <></>
            }
    </main>
    </AppLayout>
  )
}

import '../styles/auth.css'
import AuthLayout from '../components/AuthLayout'
import React, { useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import { AiOutlineMail } from "react-icons/ai"
import { MdOutlinePassword } from "react-icons/md"

export default function Login() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const ifredirect = (searchParams.get('redirect') != null) ? searchParams.get('redirect') : ''

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const { login, isLoading } = useContext(AuthContext);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
      e.preventDefault();
      if(isLoading === false){
      try {
        if(email !== "" && password !== ""){
        setError('')
        const response = await login(email, password);
        if(response === 'success'){
            if(ifredirect !== ''){
                navigate(ifredirect);
            }
            else{
                navigate('/dashboard');
            } 
        }
        }
        else{
            setError('All fields are required')
            return;
        }
      } catch (error) {
            setError('Login failed: '+ error)
      }
    }  
    };

  return (
    <AuthLayout>
    <main>
        <section className='container px-3 2xl:px-10 xl:px-10 lg:px-10 md:px-7 mx-auto pt-0 2xl:pt-10 xl:pt-10 lg:pt-10 md:pt-5'>
            <div className='flex flex-col lg:flex-row space-y-5 lg:space-y-0 justify-between items-center'>
                <div>
                    <div className='flex'><Link to="/"><img src='/logo.png' className='w-10 h-10'/></Link></div>
                    <div className='text-white text-xl font-light mt-4 w-full lg:w-6/12 leading-relaxed tracking-wide'><b className='font-semibold'>JUST SELL</b> Helps you sell and purchase to people all around the globe.</div>
                </div>
            <form onSubmit={handleSubmit} className='authform w-full max-w-lg p-12 mb-20'>
                <div className='flex flex-row text-white text-lg font-semibold text-center border-2 border-main mb-7'>
                    <Link to={'/signup'} className='w-1/2 p-3 border-main transition-colors hover:bg-black'>Sign Up</Link>
                    <div className='w-1/2 bg-main p-3'>Log In</div>
                </div>
                <div className='fields space-y-7 mb-4'>
                    <div class="relative">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                            <AiOutlineMail color='#777'/>
                        </div>
                        <input type="email" className='!pl-10' id="email" placeholder="E-Mail" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                    </div>

                    <div class="relative">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                            <MdOutlinePassword color='#777'/>
                        </div>
                        <input type="password" className='!pl-10' id="password" placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} required/>
                    </div>
                </div>
                {error !== "" ? <div className='text-center text-red-500 font-semibold text-md mb-3'>{error}</div> : <div className='py-4'></div>}
                <button type="submit" class="btn-main w-full" disabled={isLoading}>{isLoading === false ? 'Log In' : <Bounce animating={isLoading}/>}</button>
                <div class="text-center mt-4">
                    <Link className='text-neutral-500 text-md hover:underline' to="/forgot-password">Forgot Password?</Link>
                </div>
            </form>
            </div>
        </section>
    </main>
    </AuthLayout>
  )
}

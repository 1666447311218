import '../styles/auth.css'
import AuthLayout from '../components/AuthLayout'
import React, { useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import { Link } from 'react-router-dom';
import { AiOutlineMail } from "react-icons/ai"

export default function ForgotPassword() {

    const [email, setEmail] = useState('');
    const [mailsent, setMailsent] = useState(false);
    const [error, setError] = useState('');

    const { forgotpassword, isLoading } = useContext(AuthContext);

    const handleSubmit = async (e) => {
      e.preventDefault();
      if(isLoading === false){
      try {
        if(email !== ""){
        setError('')
        const response = await forgotpassword(email);
        if(response === 'success'){
          setMailsent(true);
        }
        }
        else{
            setError('All fields are required')
            return;
        }
      } catch (error) {
            setError('failed: '+ error)
      }
    }  
    };

  return (
    <AuthLayout>
    <main>
      <section className='container px-3 2xl:px-10 xl:px-10 lg:px-10 md:px-7 mx-auto pt-0 2xl:pt-10 xl:pt-10 lg:pt-10 md:pt-5'>
            <div className='flex flex-col lg:flex-row space-y-5 lg:space-y-0 justify-between items-center'>
                <div className='w-full'>
                    <div className='flex'><Link to="/"><img src='/logo.png' className='w-10 h-10'/></Link></div>
                    <div className='text-white text-2xl font-semibold mt-4 leading-relaxed tracking-wide w-full'>Reset password</div>
                </div>
            <form onSubmit={handleSubmit} className='authform w-full max-w-lg p-12 mb-20'>
            {mailsent === false ? 
            <>
                {/* <h3 className='text-white text-2xl font-semibold text-center mb-1'>Reset your password</h3> */}
                <h6 className='text-neutral-400 text-sm text-center mb-10'>Type in your registered email address to reset password</h6>
                <div className='fields space-y-7 mb-4'>
                    <div class="relative">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                            <AiOutlineMail color='#777'/>
                        </div>
                        <input type="email" className='!pl-10' id="email" placeholder="E-Mail" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                    </div>
                </div>
                {error !== "" ? <div className='text-center text-red-500 font-semibold text-md mb-3'>{error}</div> : <div className='py-4'></div>}
                <button type="submit" class="btn-main w-full" disabled={isLoading}>{isLoading === false ? 'Submit' : <Bounce animating={isLoading}/>}</button>
            </>
            : 
            <>
            <h3 className='text-white text-2xl font-semibold text-center mb-4'>Recovery Email Sent!</h3>
            <div className='text-neutral-400 text-center'>Please check your email. We have sent a password recovery instructions. Verification link will expire in 5 minutes.</div>
            <div class="btn-main w-full uppercase text-center mt-10">Thank You</div>
            </>
            }
            </form>
            </div>
        </section>
    </main>
    </AuthLayout>
  )
}

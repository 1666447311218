import React from 'react';
import AuthHeader from './AuthHeader';
import AuthFooter from './AuthFooter';

const AuthLayout = ({ children }) => {
  return (
    // <html lang="en">
      // <body className='' style={{ backgroundImage: `url('/images/authbg.png')`, backgroundRepeat: 'no-repeat', backgroundSize: '100vw 50vh' }}>
        <div className='flex min-h-screen flex-col items-center justify-between relative'>
        <div className='absolute top-1/4 -mt-6 left-0'><img src='/images/auth.png'/></div>  
        <AuthHeader />
        {children}
        <AuthFooter />
        </div>
      // </body> 
    // </html>
  );
};

export default AuthLayout;